import axios from '@axios'

const PREFIX = '/historico-consumo'

export default {
  getPaginado(page, pageSize, filtros, filtroString) {
    return axios.post(`${PREFIX}/paged?pagina=${page}&tamanhoPagina=${pageSize}&multiFiltro=${filtroString}`, filtros)
  },

  getRelatorioConsumo(id, filtro) {
    return axios.post(`${PREFIX}/${id}/relatorio-consumo`, filtro)
  },

  enviarEmLote(filtroDocs) {
    return axios.post(`${PREFIX}/enviar-email`, filtroDocs)
  },

  getObservacoes(id) {
    return axios.get(`${PREFIX}/${id}/observacao`)
  },

  removerObservacao(id) {
    return axios.delete(`${PREFIX}/observacao/${id}/remover`)
  },

  salvarObservacao(observacao) {
    return axios.post(`${PREFIX}/observacao/salvar`, observacao)
  },

  baixarExcelEmpresa(filtroDocs, id) {
    return axios.post(`${PREFIX}/${id}/baixa-excel-empresa`, filtroDocs)
  },

  baixarEmExcel(tipoCnd, filtroDoc) {
    return axios.post(`${PREFIX}/${tipoCnd}/baixa-excel`, filtroDoc)
  },

  deletarExcel(url, nomeArquivo) {
    axios.post(`${PREFIX}/deletar-excel?url=${url}&nomeArquivo=${nomeArquivo}`)
  },
}
