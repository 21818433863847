var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-row',{staticClass:"d-flex justify-content-end align-items-center mb-1"},[_c('b-col',{attrs:{"cols":"auto"}},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"text-primary",attrs:{"title":"Acompanhe o comsumo dos créditos utilizados pela sua empresa. Cada solicitação de atualização de uma rotina usa uma quantidade de créditos especifico, sendo 01 para Situação fiscal, 02 DCTFWeb, 02 DAS e 01 para Leitura de mensagens no e-CAC. "}},[_c('feather-icon',{attrs:{"icon":"InfoIcon","size":"25","stroke-width":"0.8"}})],1)]),_c('b-col',{attrs:{"cols":"auto"}},[_c('download-em-excel',{attrs:{"filtro":_vm.filtroAtual,"tipo":"Histórico consumo","alterar-style":"padding: 0.8rem;","api":_vm.apiHistoricoConsumo,"get-info-extra":_vm.montarDoc}})],1)],1),_c('relatorio-consumo-list',{ref:"relatorioConsumoList",attrs:{"botoes-opcoes":_vm.botoesOption,"visualizacao-opcoes":_vm.visualizacao,"filtros":_vm.filtros,"filtros-rapidos":_vm.filtrosRapidos,"tem-filtro-extra":true,"filtro-extra-custom":5},scopedSlots:_vm._u([{key:"custom",fn:function(ref ){
var props = ref.props;
return [(props.column.field === 'consumoCaixaPostal' ||
          props.column.field === 'consumoDAS' ||
          props.column.field === 'consumoDCTFWeb' ||
          props.column.field === 'consumoSituacaoFiscal' ||
          props.column.field === 'consumoGeralSerpro' ||
          props.column.field === 'periodo')?_c('b-badge',{attrs:{"variant":"light-primary"}},[_vm._v(" "+_vm._s(props.row[props.column.field])+" ")]):_vm._e()]}},{key:"acoes",fn:function(ref ){
          var props = ref.props;
return [_c('acoes',{ref:("acoes" + (props.row.id)),attrs:{"grid-props":props,"api":_vm.apiHistoricoConsumo,"filtros-rapidos":_vm.filtrosRapidos,"filtros-tipo-cnd":_vm.filtroString,"filtro-atual":_vm.filtroAtual},on:{"enviar-email":function($event){return _vm.enviarEmailLinha($event.linha, $event.email)}}})]}}])},[_c('template',{slot:"filtro-extra"},[_c('b-row',[_c('b-col',{attrs:{"cols":"5"}},[_c('v-select',{ref:"SelectFiltroDocs",attrs:{"options":_vm.filtrosRapidosDocs,"clearable":true,"multiple":"","deselect-from-dropdown":"","placeholder":"Tipos de rotinas"},on:{"input":function($event){return _vm.mudarDocSelecionado($event)}},model:{value:(_vm.filtroString),callback:function ($$v) {_vm.filtroString=$$v},expression:"filtroString"}})],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-select',{on:{"input":function($event){return _vm.mudarFiltroData($event? _vm.filtroCustomData : _vm.filtroCustomAno )}},model:{value:(_vm.mensal),callback:function ($$v) {_vm.mensal=$$v},expression:"mensal"}},[_c('b-form-select-option',{attrs:{"value":true}},[_vm._v(" Mensal ")]),_c('b-form-select-option',{attrs:{"value":false}},[_vm._v(" Anual ")])],1)],1),_c('b-col',{attrs:{"cols":"4"}},[(_vm.mensal)?_c('b-form-input',{attrs:{"type":"month"},on:{"change":function($event){return _vm.mudarFiltroData($event)}},model:{value:(_vm.filtroCustomData),callback:function ($$v) {_vm.filtroCustomData=$$v},expression:"filtroCustomData"}}):_c('b-select',{staticClass:"search-items",attrs:{"name":"filtroSelecao","options":_vm.anos},on:{"input":function($event){return _vm.mudarFiltroData($event)}},model:{value:(_vm.filtroCustomAno),callback:function ($$v) {_vm.filtroCustomAno=$$v},expression:"filtroCustomAno"}})],1)],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }