export default [{
  label: 'Razão social',
  field: 'razaoSocial',
  editable: true,
  evento: null,
},
{
  label: 'CNPJ/CPF',
  field: 'cadastroFederal',
  tdClass: 'col-nowrap col-tamanho-cnpj',
},
{
  label: 'Consumo Situação fiscal',
  field: 'consumoSituacaoFiscal',
  type: 'custom',
  tdClass: 'text-center',
  visible: true,
},
{
  label: 'Consumo DCTFWeb',
  field: 'consumoDCTFWeb',
  type: 'custom',
  tdClass: 'text-center',
  visible: true,
},
{
  label: 'Consumo DAS',
  field: 'consumoDAS',
  type: 'custom',
  tdClass: 'text-center',
  visible: true,
},
{
  label: 'Consumo Caixa postal e-CAC',
  field: 'consumoCaixaPostal',
  type: 'custom',
  tdClass: 'text-center',
  visible: true,
},
{
  label: 'Consumo Geral Serpro',
  field: 'consumoGeralSerpro',
  type: 'custom',
},
{
  label: 'Período',
  field: 'periodo',
  type: 'custom',
  sortable: false,
},
]
