<template>
  <b-card>

    <b-row class="d-flex justify-content-end align-items-center mb-1">
      <b-col cols="auto">
        <div
          v-b-tooltip.hover
          title="Acompanhe o comsumo dos créditos utilizados pela sua empresa. Cada solicitação de atualização de uma rotina usa uma quantidade de créditos especifico, sendo 01 para Situação fiscal, 02 DCTFWeb, 02 DAS e 01 para Leitura de mensagens no e-CAC. "
          class="text-primary"
        >
          <feather-icon
            icon="InfoIcon"
            size="25"
            stroke-width="0.8"
          />
        </div>
      </b-col>
      <b-col cols="auto">
        <download-em-excel
          :filtro="filtroAtual"
          tipo="Histórico consumo"
          alterar-style="padding: 0.8rem;"
          :api="apiHistoricoConsumo"
          :get-info-extra="montarDoc"
        />
      </b-col>
      <!-- <b-col cols="auto">
        <b-btn
          variant="none"
          class="btn btn-outline-primary"
          size="sm"
          style="white-space: nowrap; padding: 0.75rem 1rem;"
          @click="enviarEmailLoteGeral()"
        >
          <feather-icon
            icon="SendIcon"
            class="text-primary"
            size="15"
          />
          Enviar por e-mail
        </b-btn>
      </b-col> -->
      <!--
      <b-col cols="auto">
        <v-select
          id="baixaHistoricoSelect"
          :ref="`SelectFiltroDocs`"
          v-model="tipoDownload"
          v-b-tooltip.hover
          :options="opcoesBaixaEmLote"
          :clearable="true"
          label="nome"
          placeholder="Baixar documentos"
          style="font-size: 14.1px; width: 230px;"
          @input="baixaEmLoteGeral()"
        />
      </b-col>
      -->
    </b-row>
    <relatorio-consumo-list
      ref="relatorioConsumoList"
      :botoes-opcoes="botoesOption"
      :visualizacao-opcoes="visualizacao"
      :filtros="filtros"
      :filtros-rapidos="filtrosRapidos"
      :tem-filtro-extra="true"
      :filtro-extra-custom="5"
    >
      <template
        slot="custom"
        slot-scope="{ props} "
      >
        <b-badge
          v-if="props.column.field === 'consumoCaixaPostal' ||
            props.column.field === 'consumoDAS' ||
            props.column.field === 'consumoDCTFWeb' ||
            props.column.field === 'consumoSituacaoFiscal' ||
            props.column.field === 'consumoGeralSerpro' ||
            props.column.field === 'periodo'"
          variant="light-primary"
        >
          {{ props.row[props.column.field] }}
        </b-badge>
      </template>

      <template
        slot="filtro-extra"
      >
        <b-row>
          <b-col cols="5">
            <v-select
              :ref="`SelectFiltroDocs`"
              v-model="filtroString"
              :options="filtrosRapidosDocs"
              :clearable="true"
              multiple
              deselect-from-dropdown
              placeholder="Tipos de rotinas"
              @input="mudarDocSelecionado($event)"
            />
          </b-col>
          <b-col cols="3">
            <b-form-select
              v-model="mensal"
              @input="mudarFiltroData($event? filtroCustomData : filtroCustomAno )"
            >
              <b-form-select-option :value="true">
                Mensal
              </b-form-select-option>
              <b-form-select-option :value="false">
                Anual
              </b-form-select-option>
            </b-form-select>
          </b-col>
          <b-col cols="4">
            <b-form-input
              v-if="mensal"
              v-model="filtroCustomData"
              type="month"
              @change="mudarFiltroData($event)"
            />
            <b-select
              v-else
              v-model="filtroCustomAno"
              name="filtroSelecao"
              class="search-items"
              :options="anos"
              @input="mudarFiltroData($event)"
            />
          </b-col>
        </b-row>
      </template>
      <template
        slot="acoes"
        slot-scope="{ props} "
      >
        <acoes
          :ref="`acoes${props.row.id}`"
          :grid-props="props"
          :api="apiHistoricoConsumo"
          :filtros-rapidos="filtrosRapidos"
          :filtros-tipo-cnd="filtroString"
          :filtro-atual="filtroAtual"
          @enviar-email="enviarEmailLinha($event.linha, $event.email)"
        />
      </template>
    </relatorio-consumo-list>
  </b-card>
</template>

<style lang="scss">

.col-valores{
white-space: nowrap;
}
[dir] #baixaSelect .vs__dropdown-toggle{
border-color: var(--primary) !important;
}
</style>

<script>
import DataUtils from '@/@pilar/utils/dataUtils'
import moment from 'moment'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import colunas from './shared/components/colunas'
import api from './shared/services/api'
import '@core/scss/vue/libs/vue-select.scss'
// import { getUserInfo } from '@/auth/utils'

export default {
  name: 'RelatorioConsumoView',
  components: {
    vSelect,
    RelatorioConsumoList: () => import('@pilar/components/visualizacao-base/VisualizacaoBase.vue'),
    Acoes: () => import('./shared/components/acoes/Acoes.vue'),
    DownloadEmExcel: () => import('@app/download-excel/DownloadEmExcel.vue'),
  },
  data() {
    return {
      apiHistoricoConsumo: api,
      botoesOption: null,
      linha: null,
      mensal: true,
      visualizacao: {
        placeholderBusca: 'Buscar por: CNPJ/CPF, Razão social',
        acaoClass: 'col-td-acao-3',
        disabledCheckbox: false,
      },

      tipoDownload: null,
      filtroTipoDownload: null,
      filtroCustomData: '',
      filtroCustomAno: '',
      filtrosDefaultDocs: [],
      filtrosRapidosDocs: [],

      filtros: [],
      filtrosBase: [{ colunas: ['CadastroFederalSemMascara', 'CadastroFederal', 'RazaoSocial'], valor: null }],
      filtrosRapidos: [
        {
          id: 'Com consumo',
          colunas: ['ComConsumo'],
          label: 'Com consumo',
          valor: 'true',
        },
        {
          id: 'Sem consumo',
          colunas: ['SemConsumo'],
          label: 'Sem consumo',
          valor: 'false',
        },
      ],
      filtroAtual: {},
      filtroString: [],

      arrayColunas: ['consumoSituacaoFiscal', 'consumoDAS', 'consumoCaixaPostal', 'consumoDCTFWeb'],
      opcoesBaixaEmLote: ['Arquivo Único', 'Por Empresa', 'Por rotina', 'Log de consumo'],
    }
  },
  created() {
    const dataUtils = new DataUtils()
    this.anos = dataUtils.getArrayAnos('2024')

    this.visualizacao.colunas = colunas.map(coluna => ({
      ...coluna,
      visible: true,
    }))
    this.montarColunasPermitidas()
    this.filtrosRapidosDocs = this.filtrosDefaultDocs

    const mes = moment().format('yyyy-MM')
    this.mensal = !this.$route.query.filtroAno

    if (this.mensal) {
      this.filtroCustomData = this.$route.query.filtroMes ? this.$route.query.filtroMes : mes
    } else {
      this.filtroCustomAno = this.$route.query.filtroAno
    }

    if (!this.filtroCustomData) {
      this.filtroCustomData = mes
    }

    this.filtros = [...this.filtrosBase, { valor: this.mensal ? this.filtroCustomData : `${this.filtroCustomAno}-01`, colunas: [this.mensal ? 'Periodo' : 'PeriodoAnual'] }]

    this.visualizacao.evento = this.carregar
  },
  mounted() {
    this.aplicarFiltroQuery()
  },
  updated() {
    this.aplicarFiltroQuery()
  },
  methods: {
    aplicarFiltroQuery() {
      this.$nextTick(() => {
        const params = this.$route.query
        if (params && this.$refs.relatorioConsumoList) {
          const filtroRapido = params.filtroRapido
            ? this.filtrosRapidos.find(f => f.id === params.filtroRapido)
            : null
          this.$refs.relatorioConsumoList.aplicarFiltro(params.busca, filtroRapido)
        }
      })
    },
    carregar(pagina, tamanhoPagina, filtro) {
      this.filtroAtual = filtro
      return api.getPaginado(pagina, tamanhoPagina, filtro, this.filtroString)
    },
    montarColunasPermitidas() {
      const userData = JSON.parse(localStorage.getItem('userData'))
      const acessos = userData.ability
      this.visualizacao.colunas.forEach(col => {
        const coluna = col.field.toLowerCase()
        if (!(coluna === 'razaoSocial'.toLowerCase() || coluna === 'cadastroFederal'.toLowerCase() || coluna === 'consumoGeralSerpro'.toLowerCase() || coluna === 'periodo'.toLowerCase())
        && !(coluna === 'consumoSituacaoFiscal'.toLowerCase() && acessos.some(o => o.subject === 'situacaofiscal_federal'))
        && !(coluna === 'consumoDAS'.toLowerCase() && acessos.some(o => o.subject === 'das'))
        && !(coluna === 'consumoCaixaPostal'.toLowerCase() && acessos.some(o => o.subject === 'caixapostal_ecac'))
        && !(coluna === 'consumoDCTFWeb'.toLowerCase() && acessos.some(o => o.subject === 'declaracao_dctfweb'))) {
          this.visualizacao.colunas = this.visualizacao.colunas.filter(obj => obj.field.toLowerCase() !== coluna)
        }
      })
      this.montarFiltrosDeDocumentos()
    },
    montarFiltrosDeDocumentos() {
      this.visualizacao.colunas.forEach(col => {
        const coluna = col.field.toLowerCase()
        if (coluna === 'consumoSituacaoFiscal'.toLowerCase()) {
          this.filtrosDefaultDocs.push('Situação fiscal')
        } else if (coluna === 'consumoDCTFWeb'.toLowerCase()) {
          this.filtrosDefaultDocs.push('DCTFWeb')
        } else if (coluna === 'consumoDAS'.toLowerCase()) {
          this.filtrosDefaultDocs.push('DAS')
        } else if (coluna === 'consumocaixapostal'.toLowerCase()) {
          this.filtrosDefaultDocs.push('Caixa Postal')
        }
      })
      this.filtrosDefaultDocs.unshift('Todos')
    },
    mudarDocSelecionado(docs) {
      if (docs && docs.find(find => find === 'Todos')) {
        this.filtrosRapidosDocs = ['Todos']
        this.visibleColunas()
      } else {
        this.filtrosRapidosDocs = this.filtrosDefaultDocs
        this.visibleColunas()
      }
      this.executarFiltro()
    },
    executarFiltro() {
      this.$refs.relatorioConsumoList.montarGrid(this.$refs.relatorioConsumoList.enviarServidor)
    },
    visibleColunas() {
      this.visualizacao.colunas.forEach(coluna => {
        if (this.filtroString.length > 0 && !this.filtroString.includes('Todos')) {
          const filtroStringModificada = this.filtroString.map(s => s.replace(/[.\-\\/\s]/g, '').toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
          if (!filtroStringModificada.some(s => s.includes(coluna.field.replace('consumo', '').toLowerCase()))
            && this.arrayColunas.includes(coluna.field)) {
            // eslint-disable-next-line no-param-reassign
            coluna.visible = false
          } else {
            // eslint-disable-next-line no-param-reassign
            coluna.visible = true
          }
        } else {
          if (this.filtroString.length > 0) {
            this.filtroString = ['Todos']
          }
          // eslint-disable-next-line no-param-reassign
          coluna.visible = true
        }
      })
    },
    mudarFiltroData(data) {
      const dataFinal = this.mensal ? data : `${data}-01`
      const coluna = this.mensal ? ['Periodo'] : ['PeriodoAnual']
      const filtroData = { valor: dataFinal, colunas: coluna }

      const index = this.$refs.relatorioConsumoList.enviarServidor.filtros.findIndex(where => where.colunas.length === 1 && where.colunas.some(any => filtroData === null || any === (this.mensal ? 'PeriodoAnual' : 'Periodo')))

      if (index >= 0) {
        this.$refs.relatorioConsumoList.enviarServidor.filtros.splice(index, 1)
      }

      const filtronew = [...this.filtrosBase, filtroData]
      this.filtros = filtronew
      this.$refs.relatorioConsumoList.filtroImutavel = [...filtronew]
      this.$refs.relatorioConsumoList.aplicarFiltroRapido(filtroData, false)
    },

    montarDoc() {
      if (this.$refs.relatorioConsumoList) {
        const empresas = this.$refs.relatorioConsumoList.enviarServidor.linhasSelecionadas

        return {
          Agrupamento: this.tipoDownload,
          Docs: this.filtroString,
          Empresas: !empresas ? undefined : empresas.map(x => x.id),
          Filtro: this.filtroAtual,
        }
      }
      return undefined
    },
    enviarEmailLoteGeral() {
      let empresas = this.$refs.relatorioConsumoList.enviarServidor.linhasSelecionadas

      this.isLoading = true
      if (!empresas) {
        empresas = this.$refs.relatorioConsumoList.gridInfo.dados
      }
      const empresaEmail = empresas.map(empresa => ({ empresaId: empresa.id, emailsCC: null, email: empresa.email ? empresa.email : null }))
      this.doc = this.montarDoc()
      this.doc.empresasEmail = [...empresaEmail]
      api.enviarEmLote(this.doc).then(payload => {
        this.isLoading = false
        if (payload.data.length > 0) {
          this.empresasEmail = payload.data

          this.$refs.empresaSemEmailList.show()
        }
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'E-mails enviados com sucesso',
            icon: 'fa-solid fa-circle-check',
            variant: 'success',
          },
        })
      }).catch(err => {
        this.isLoading = false
        this.$erro('Erro ao enviar os e-mails', 'danger')
        throw err
      })
    },
    enviarEmailLinha(linha, email) {
      this.isLoading = true
      const empresaEmail = {
        empresaId: linha.id,
        email: email.emailsPara,
        emailAdicionalEmpresa: email.emailsCc,
        mensagensAdicional: email.mensagensAdicional,
        definirEmailPadrao: email.definirEmailPadroes,
      }
      api.enviarEmLote({ docs: this.filtroString, empresasEmail: [empresaEmail], filtro: this.filtroAtual })
        .then(() => {
          this.isLoading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'E-mail enviado com sucesso',
              icon: 'fa-solid fa-circle-check',
              variant: 'success',
            },
          })
        }).catch(err => {
          this.isLoading = false
          this.$erro('Problemas no envio do e-mail, confira os dados e tente novamente!', 'danger')
          throw err
        })
    },
    baixaEmLoteGeral() {
      this.isLoading = true
      const doc = this.montarDoc()
      api.baixaEmLote(doc, '')
      this.isLoading = false
      this.tipoDownload = null
    },
  },
}
</script>
